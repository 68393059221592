import { extendTheme, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const ChakraTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    // useSystemColorMode: true,
  },
  colors: {
    brand: {
      primary: '#EB853F',
      secondary: '#A29380',
    },
    // next 4 are for alerts and toasts
    blue: {
      // 100: '#EB853F', // needed for other variants
      // 200: '#EB853F', // needed for other variants
      500: '#6C635D', // using only solid variant
    },
    orange: {
      500: '#D67B28',
    },
    green: {
      500: '#226E45',
    },
    red: {
      500: '#842B0F',
    },
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Poppins',
      },
      html: {
        scrollBehavior: 'smooth',
      },
    }),
  },
  components: {
    Container: {
      baseStyle: {
        maxW: '1180px',
        padding: { base: '0 20px', xl: '0' },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'Poppins, Arial, sans-serif',
        textAlign: 'center',
        transition: 'all ease-in-out 0.2s',
        _focus: { boxShadow: 'none', outline: 'none' },
      },
      sizes: {
        lg: {
          fontSize: 'lg',
          fontWeight: 'bold',
          padding: '0.4rem 0.75rem',
        },
      },
      variants: {
        solid: {
          bg: 'brand.primary',
          color: '#fff',
          border: '1px solid',
          borderColor: 'brand.primary',
          rounded: '4px',
          _hover: {
            bg: 'rgba(235, 133, 63, 0.9)',
            borderColor: 'rgba(235, 133, 63, 0.9)',
          },
          _active: {
            bg: 'rgba(235, 133, 63, 0.9)',
          },
        },
        outline: {
          bg: 'transparent',
          color: 'brand.primary',
          border: '1px solid',
          borderColor: 'brand.primary',
          rounded: '4px',
          _hover: {
            bg: 'brand.primary',
            color: '#fff',
          },
        },
        solid_white: {
          bg: '#fff',
          rounded: '5px',
          border: '1px solid #fff',
          fontWeight: 'bold',
          color: '#181818',
        },
        outline_white: {
          bg: 'transparent',
          rounded: '5px',
          border: '1px solid #9F9890',
          fontWeight: 'normal',
          color: '#9F9890',
        },
        outline_grey: {
          bgColor: 'transparent',
          border: '1px solid #9B8D7A',
          rounded: '17px',
          color: '#fff',
          _hover: { bgColor: '#9B8D7A' },
        },
      },
    },
    Text: {
      variants: {
        heading: {
          fontSize: '2xl',
          fontWeight: '700',
          color: '#fff',
        },
        subheading: {
          color: '#fff',
        },
        description: {
          fontSize: '14px',
          color: 'gray.500',
        },
        sectionHeading: {
          fontSize: 'xl',
          fontWeight: 500,
          color: 'gray.200',
        },
        tableContent: {
          fontSize: 14,
          color: 'gray.500',
        },
        tableHeading: {
          fontSize: 14,
          color: 'gray.400',
        },
        caption: {
          fontSize: 12,
          color: 'gray.500',
        },
      },
    },
    Link: {
      baseStyle: {
        _focus: { boxShadow: 'none', outline: 'none' },
      },
    },
    Alert: {
      baseStyle: {
        // color: 'gray.800',
        // description: { color: 'gray.600' },
        // title: { color: 'gray.700' },
        // closeButton: { color: 'gray.700' },
      },
      // variants: {
      //   subtle: {
      //     description: { color: '#000' },
      //     title: { color: '#000' },
      //   },
      // },
    },
  },
});
