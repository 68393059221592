import * as React from 'react';

import { ClerkProvider, ClerkLoaded } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import { useNavigate } from 'react-router-dom';

const AuthProviderWithHistory: React.FC = ({ children }) => {
  const frontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;

  const navigate = useNavigate();

  return (
    <ClerkProvider
      frontendApi={frontendApi}
      navigate={(to) => navigate(to)}
      appearance={{
        baseTheme: dark,
        variables: {
          borderRadius: '4px',
          colorPrimary: '#EB853F',
          colorBackground: '#18171D',
          colorInputBackground: '#18171D',
        },
      }}
    >
      <ClerkLoaded>{children}</ClerkLoaded>
    </ClerkProvider>
  );
};

export default AuthProviderWithHistory;
