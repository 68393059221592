import * as React from 'react';

import {
  Box,
  Image,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
} from '@chakra-ui/react';
import { useSignIn, useUser } from '@clerk/clerk-react';
import { AdvancedImage, placeholder, responsive } from '@cloudinary/react';
import {
  Cloudinary,
  Transformation,
  ImageTransformation,
} from '@cloudinary/url-gen';
import { Reshape } from '@cloudinary/url-gen/actions/reshape';
import { scale, thumbnail } from '@cloudinary/url-gen/actions/resize';
import { Position } from '@cloudinary/url-gen/qualifiers';
import { face } from '@cloudinary/url-gen/qualifiers/focusOn';
import { compass, focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { image } from '@cloudinary/url-gen/qualifiers/source';
import querystring from 'qs';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import AddEventModal from 'src/components/AddEventModal/AddEventModal';
import { useCloudImage } from 'src/providers/CloudImageProvider';
import makeCalendarURLs, {
  CalendarEvent,
} from 'src/utils/helpers/makeCalendarURLs';
import notify from 'src/utils/helpers/notify';

import {
  resizeCrop,
  nameTextOverlayOnlyForHostPage,
  nameTextOverlayForCardWide,
} from '../../utils/helpers/cloudinaryTransformations';

const MyImgExample = () => {
  const { cld } = useCloudImage();

  const myImg = cld.image('mike-von-BhcutpohYwg-unsplash_rwbsw0');
  myImg
    .resize(
      thumbnail().width(680).height(280).gravity(focusOn(face())).zoom(0.6)
    )
    .reshape(
      Reshape.cutByImage(
        image('1px').transformation(
          new Transformation()
            .resize(scale().width(480).height(280))
            .backgroundColor('white')
        )
      ).position(new Position().gravity(focusOn(face())).offsetX(-100))
    );
  myImg.addTransformation(
    nameTextOverlayForCardWide({
      textString: 'Saqib Qureshi',
      fontSize: 48,
      fontWeight: '700',
      font: 'playfair display',
    })
  );

  return (
    <div>
      {<AdvancedImage cldImg={myImg} style={{ border: '2px solid #FFF' }} />}
    </div>
  );
};

const TestPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { signIn } = useSignIn();
  const { user } = useUser();
  const navigate = useNavigate();

  const redirectURL = `/event/saqib-qureshi-september-20-2021-700-pm-gmt`;
  const redirectQS = querystring.stringify({
    redirect_url: redirectURL,
  });
  const urlForPush = `/sign-in/factor-one?${redirectQS}`;

  const doSignIn = async () => {
    const res = await signIn?.create({
      identifier: 'mr+test3@gather6.com',
      strategy: 'email_code',
    });
    console.log({ res });
    navigate(urlForPush);
  };

  const updateUser = async () => {
    user?.update({ unsafeMetadata: { a: 'b' } });
    console.log(user?.unsafeMetadata);
  };

  const { cld } = useCloudImage();
  // const tempImage = cld.image('aatik-tasneem-7omHUGhhmZ0-unsplash_vxdiis');
  // const tempImage = cld.image('transparent_1024_jvijio.png');
  const baseImage = cld.image('1px');
  // const tempImage = cld.image('teal_1024_d43spu.png');

  baseImage.addTransformation(
    new Transformation().resize(scale().width(280).height(200))
  );
  baseImage.addTransformation(
    nameTextOverlayOnlyForHostPage({
      textString: 'Saqib Qureshi',
      fontSize: 48,
      fontWeight: '700',
      font: 'playfair display',
    })
  );

  // tempImage.addTransformation(resizeCrop({ width: 200, height: 400 }));
  // tempImage.addTransformation(
  //   nameTextOverlayOnlyForHostPage({
  //     textString: 'Saqib Qureshi',
  //     fontSize: 48,
  //     fontWeight: '700',
  //     font: 'playfair display',
  //   })
  // );

  const doNotify = () => {
    notify({
      title: 'Participant Hand Raised',
      message: `Baba Yaga raised their hands`,
      type: 'success',
      duration: null,
    });
  };

  const calendarEvent: CalendarEvent = {
    name: 'Saqib Qureshi',
    details: 'Saqib Qureshi',
    location:
      'http://test.gather6.com/event/manu-yaga-november-18-2021-800-am-gmt',
    startsAt: '2021-11-20T19:00:00.000Z',
    endsAt: '2021-11-20T20:00:00.000Z',
  };

  const calendarURLs = makeCalendarURLs(calendarEvent);
  // console.log({ calendarURLs });

  return (
    <Box p="20px">
      <Box p={8}>
        <Button variant="outline" onClick={doNotify}>
          Info
        </Button>
      </Box>
      <Box p={8}>
        <Menu autoSelect={false}>
          <MenuButton as={Button} rightIcon={<FiChevronDown />}>
            Your Cats
          </MenuButton>
          <MenuList>
            <MenuItem
              as={Link}
              href={calendarURLs.google}
              isExternal
              _hover={{ textDecoration: 'none' }}
            >
              Google Calendar
            </MenuItem>
            <MenuItem
              as={Link}
              href={calendarURLs.outlook}
              isExternal
              _hover={{ textDecoration: 'none' }}
            >
              Microsoft Outlook
            </MenuItem>
            <MenuItem
              as={Link}
              href={calendarURLs.office365}
              isExternal
              _hover={{ textDecoration: 'none' }}
            >
              Office 365
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <Box border="2px solid #FFF">
        <AdvancedImage
          style={{ border: '1px solid #FFF' }}
          // width={500}
          // height={400}
          cldImg={baseImage}
          // alt="Segun Adebayo"
        />
      </Box>
      <MyImgExample />
    </Box>
  );
};

export default TestPage;
