import * as React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  VStack,
  Text,
  Box,
} from '@chakra-ui/react';

import Logo from '../Logo/Logo';

export type LoadingOverlayPropsType = { isOverlayOpen: boolean; text?: string };

const LoadingOverlay = ({ isOverlayOpen, text }: LoadingOverlayPropsType) => {
  return (
    <Modal
      onClose={() => {}}
      size="full"
      isOpen={isOverlayOpen}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent>
        <Center h="100vh" bg="black" opacity="0.9">
          <VStack>
            {/* <Center> */}
            <Box w="60px" h="60px" mb="20px">
              <Logo testId="loading-overlay-logo" color={'#AD6838'} />
            </Box>
            {/* </Center> */}
            {text && <Text color="#A29380">{text}</Text>}
          </VStack>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default LoadingOverlay;
