import * as React from 'react';

import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import { ChakraTheme } from 'src/chakra-theme';

export type NotifyType = {
  title: string;
  message: string;
  type: 'info' | 'success' | 'error' | 'warning';
  duration?: UseToastOptions['duration'];
};

const toast = createStandaloneToast({ theme: ChakraTheme });

const notify = ({ title, message, type, duration = null }: NotifyType) =>
  toast({
    title,
    description: message,
    position: 'top-right',
    duration,
    isClosable: true,
    status: type,
    variant: 'solid',
  });

export default notify;
