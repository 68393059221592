import * as React from 'react';

import { Box } from '@chakra-ui/react';
import { SignUp } from '@clerk/clerk-react';

const SignUpScreen = () => {
  return (
    <Box bgColor={'#18171D'} h={'100vh'}>
      <SignUp routing="path" path="/sign-up" />
    </Box>
  );
};

export default SignUpScreen;
