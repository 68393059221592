import * as React from 'react';

import { Cloudinary } from '@cloudinary/url-gen';

export interface CloudImageContextType {
  cld: Cloudinary;
}

export const CloudImageContext = React.createContext<CloudImageContextType>(
  null!
);

const CloudImageProvider = (props: React.PropsWithChildren<{}>) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'gather6',
    },
  });

  let contextValue = {
    cld,
  };

  return (
    <CloudImageContext.Provider value={contextValue}>
      {props.children}
    </CloudImageContext.Provider>
  );
};

export default CloudImageProvider;

export const useCloudImage = () => {
  const context = React.useContext(CloudImageContext);
  if (!context) {
    throw new Error('useCloudImage must be used within the CloudImageProvider');
  }
  return context;
};
