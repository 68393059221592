import * as React from 'react';

import { FetchUserDetailsByIdQuery } from 'src/generated/graphql';

export interface G6AppStateProviderContextType {
  isHeaderVisible?: boolean;
  isLogoLinkedToHome?: boolean;
  isHostLinkVisible?: boolean;
  isUserMenuVisible?: boolean;
  isBannerVisible: boolean;
  setbannerAlwaysOn?: any;
  setBannerTitle?: React.Dispatch<React.SetStateAction<string>>;
  bannerTitle?: string;
  bannerButtonAction?: () => void;
  userDetails: FetchUserDetailsByIdQuery | undefined;
  setUserDetails: React.Dispatch<
    React.SetStateAction<FetchUserDetailsByIdQuery | undefined>
  >;
}

export const G6AppContext = React.createContext<G6AppStateProviderContextType>(
  null!
);

interface G6AppStateProviderProps {
  children: React.ReactNode;
}

const G6AppStateProvider = ({ children }: G6AppStateProviderProps) => {
  const [isBannerVisible, setbannerAlwaysOn] = React.useState<boolean>(false);
  const [bannerTitle, setBannerTitle] = React.useState('');
  const [userDetails, setUserDetails] = React.useState<
    FetchUserDetailsByIdQuery | undefined
  >();

  return (
    <G6AppContext.Provider
      value={{
        isBannerVisible,
        setbannerAlwaysOn,
        setBannerTitle,
        bannerTitle,
        userDetails,
        setUserDetails,
      }}
    >
      {children}
    </G6AppContext.Provider>
  );
};

export default G6AppStateProvider;

export const useG6AppState = () => {
  const context = React.useContext(G6AppContext);
  if (!context) {
    throw new Error('useG6AppState must be used within the G6Provider');
  }
  return context;
};
