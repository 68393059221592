// import { Box } from '@chakra-ui/react';

import { ReactComponent as LogoSvg } from './logo.svg';

export type LogoPropsType = {
  color: string;
  testId?: string
};

const Logo = ({ color, testId }: LogoPropsType) => <LogoSvg data-testid={testId} fill={color} />;

export default Logo;
