import posthog, { Properties } from 'posthog-js';

export type SendEventPropsType = {
  eventName: string;
  eventData: Properties;
};

export type IdentifyPropsType = {
  name: string;
  identity: string;
  email: string;
  phone?: string;
  gender?: 'M' | 'F';
  dateOfBirth?: Date;
  firstName: string;
  lastName: string;
  isHost: boolean;
};

const init = () => {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: 'https://app.posthog.com',
    autocapture: false,
    capture_pageview: true,
    disable_session_recording: true,
  });
};

const sendEvent = ({ eventName, eventData }: SendEventPropsType) => {
  posthog.capture(eventName, eventData);
};

const logout = () => {
  posthog.reset();
};

const identify = (user: IdentifyPropsType) => {
  // const {identity, ...rest} = user
  posthog.identify(user.identity, {
    Name: user.name, // String
    Email: user.email, // Email address of the user
    email: user.email, // this does some automatic stuff, can see in event view
    'First Name': user.firstName,
    'Last Name': user.lastName,
    'Is Host': user.isHost,
    // 'Host Onboarded': user.isHost,
    ...(user.phone && { Phone: user.phone }), // "+14155551234",           // Phone (with the country code)
    ...(user.gender && { Gender: user.gender }),
    ...(user.dateOfBirth && { DOB: user.dateOfBirth }),
  });
};

const analytics = { init, sendEvent, logout, identify };

export default analytics;
