import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import {
  SentryErrorBoundary,
  SentryErrorBoundaryProps,
} from './SentryErrorBoundary';

export function initErrorHandler() {
  // if dev build, then local
  let environment = 'local';
  // if production build then rely on env var
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_G6_ENV) {
    environment = process.env.REACT_APP_G6_ENV;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  });
}

function FallbackComponent() {
  return <div>Oops! Something went wrong while loading the component.</div>;
}

type ErrorBoundaryProps = {
  children: JSX.Element;
  fallback?: SentryErrorBoundaryProps['fallback'];
};

export function ErrorBoundary(props: ErrorBoundaryProps) {
  const fallback = props.fallback || FallbackComponent;

  return (
    <SentryErrorBoundary fallback={fallback}>
      {props.children}
    </SentryErrorBoundary>
  );
}
