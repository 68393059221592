import { ImageTransformation } from '@cloudinary/url-gen';
import {
  format,
  quality,
  defaultImage,
} from '@cloudinary/url-gen/actions/delivery';
import { gradientFade } from '@cloudinary/url-gen/actions/effect';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { Reshape } from '@cloudinary/url-gen/actions/reshape';
import {
  crop,
  fill,
  scale,
  thumbnail,
} from '@cloudinary/url-gen/actions/resize';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { Color } from '@cloudinary/url-gen/qualifiers/color';
import {
  south,
  southWest,
  north,
  center,
} from '@cloudinary/url-gen/qualifiers/compass';
import { face } from '@cloudinary/url-gen/qualifiers/focusOn';
import { auto as autoFormat } from '@cloudinary/url-gen/qualifiers/format';
import { focusOn, compass } from '@cloudinary/url-gen/qualifiers/gravity';
import { Position } from '@cloudinary/url-gen/qualifiers/position';
import { auto as autoQuality } from '@cloudinary/url-gen/qualifiers/quality';
import { image, text } from '@cloudinary/url-gen/qualifiers/source';
import { TextStyle } from '@cloudinary/url-gen/qualifiers/textStyle';

// https://stackblitz.com/edit/cloudinary-base-design-patterns?file=App.tsx

function profilePicture(width: number, height: number): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        thumbnail()
          .width(width)
          .height(height)
          .gravity(focusOn(face()))
          .zoom(0.5)
      )
      .delivery(defaultImage('default_image_reverse_zbevcb.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function profilePictureForOG(): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        thumbnail().width(1200).height(627).gravity(focusOn(face())).zoom(0.5)
      )
      .delivery(defaultImage('default_image_reverse_zbevcb.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

export type ProfilePictureForCardTransformationPropsType = {
  width: number;
  height: number;
};

function profilePictureForCard({
  width,
  height,
}: ProfilePictureForCardTransformationPropsType): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        thumbnail()
          .width(width)
          .height(height)
          .gravity(focusOn(face()))
          .zoom(0.6)
      )
      .backgroundColor('black')
      .effect(gradientFade().strength(20).verticalStartPoint(-0.4))
      .delivery(defaultImage('default_image_tkuoso.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function profilePictureForCardNew({
  width,
  height,
}: ProfilePictureForCardTransformationPropsType): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        thumbnail().width(280).height(550).gravity(focusOn(face())).zoom(0.5)
      )
      .backgroundColor('black')
      .effect(gradientFade().strength(20).verticalStartPoint(-0.5))
      .reshape(
        Reshape.cutByImage(
          image('1px').transformation(
            new ImageTransformation()
              .resize(scale().width(280).height(350))
              .backgroundColor('white')
          )
        ).position(new Position().gravity(focusOn(face())).offsetY(70))
      )
      // .delivery(defaultImage('default_image_tkuoso.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function profilePictureForCardWide({
  width,
  height,
}: ProfilePictureForCardTransformationPropsType): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        thumbnail()
          .width(width)
          .height(height)
          .gravity(focusOn(face()))
          .zoom(0.6)
      )
      .backgroundColor('black')
      .effect(
        gradientFade()
          .strength(10)
          .verticalStartPoint(-0.2)
          .horizontalStartPoint(-0.4)
      )
      .delivery(defaultImage('default_image_tkuoso.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function profilePictureForCardWideNew(): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        thumbnail().width(680).height(280).gravity(focusOn(face())).zoom(0.8)
      )
      .backgroundColor('black')
      .effect(gradientFade().strength(20).verticalStartPoint(-0.4))
      .reshape(
        Reshape.cutByImage(
          image('1px').transformation(
            new ImageTransformation()
              .resize(scale().width(480).height(280))
              .backgroundColor('white')
          )
        ).position(new Position().gravity(focusOn(face())).offsetX(-100))
      )
      .delivery(defaultImage('default_image_tkuoso.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function deliveryFinishing(): ImageTransformation {
  return (
    new ImageTransformation()
      .delivery(defaultImage('default_image_tkuoso.png'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

export type NameTextOverlayTransformationPropsType = {
  textString: string;
  font?: string;
  fontSize?: number;
  fontWeight?: string;
  lineSpacing?: number;
  offsetY?: number;
};

const nameTextOverlayForCard = ({
  textString,
  font = 'poppins',
  fontSize = 36,
  fontWeight = 'bold',
  lineSpacing = -20,
  offsetY = 60,
}: NameTextOverlayTransformationPropsType) => {
  const textSource = source(
    text(
      textString,
      new TextStyle(font, fontSize)
        .fontWeight(fontWeight)
        .textAlignment('center')
        .lineSpacing(lineSpacing)
    ).textColor('white')
  ).position(
    new Position()
      .gravity(compass(center()))
      .offsetY(offsetY)
      .allowOverflow(false)
  );

  // console.log(textSource.toString());

  return new ImageTransformation().addTransformation(
    // `fl_text_no_trim,w_200,c_fit,${textSource.toString()}`
    `fl_text_no_trim,w_150,c_fit,${textSource.toString()}`
  );
};

const nameTextOverlayForCardWide = ({
  textString,
  font = 'poppins',
  fontSize = 36,
  fontWeight = 'normal',
  lineSpacing = -10,
}: NameTextOverlayTransformationPropsType) => {
  const textSource = source(
    text(
      textString,
      new TextStyle(font, fontSize)
        .fontWeight(fontWeight)
        .textAlignment('center')
        .lineSpacing(lineSpacing)
    ).textColor('white')
  ).position(
    new Position()
      .gravity(compass(southWest()))
      .offsetY(30)
      .offsetX(30)
      .allowOverflow(false)
  );

  return new ImageTransformation().addTransformation(
    // `fl_text_no_trim,w_200,c_fit,${textSource.toString()}`
    `w_250,c_fit,${textSource.toString()}`
  );
};

function profilePictureBackground(): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        fill()
          .aspectRatio(1.778)
          // .width(1440)
          // .height(1440)
          .gravity(focusOn(face()))
      )
      // .backgroundColor(Color.BLACK)
      // .effect(gradientFade().verticalStartPoint(-0.5))
      // .delivery(format(auto())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function mediaObjectImage(height: number): ImageTransformation {
  return (
    new ImageTransformation()
      .resize(
        scale()
          // .width(384)
          .height(height)
      )
      .delivery(defaultImage('brooke-cagle-LCcFI_26diA-unsplash_a1xohb.jpg'))
      // .delivery(format(autoFormat())) // was not working on responsively app
      .delivery(quality(autoQuality()))
  );
}

function avatarTransformation() {
  return new ImageTransformation()
    .resize(thumbnail().width(32).height(32).gravity(focusOn(face())))
    .roundCorners(byRadius(16));
}

function resizeCrop({
  width,
  height,
}: ProfilePictureForCardTransformationPropsType): ImageTransformation {
  return new ImageTransformation().resize(crop().width(width).height(height));
}

function resizeScale({
  width,
  height,
}: ProfilePictureForCardTransformationPropsType): ImageTransformation {
  return new ImageTransformation().resize(scale().width(width).height(height));
}

const nameTextOverlayOnlyForHostPage = ({
  textString,
  font = 'poppins',
  fontSize = 36,
  fontWeight = 'normal',
  lineSpacing = -10,
}: NameTextOverlayTransformationPropsType) => {
  const textSource = source(
    text(
      textString,
      new TextStyle(font, fontSize)
        .fontWeight(fontWeight)
        .textAlignment('center')
        .lineSpacing(lineSpacing)
    ).textColor('white')
  );

  return new ImageTransformation().addTransformation(
    // `fl_text_no_trim,w_200,c_fit,${textSource.toString()}`
    `w_200,c_fit,${textSource.toString()}`
  );
};

export {
  profilePicture,
  profilePictureForOG,
  profilePictureForCard,
  profilePictureForCardNew,
  profilePictureForCardWide,
  profilePictureBackground,
  mediaObjectImage,
  nameTextOverlayForCard,
  nameTextOverlayForCardWide,
  profilePictureForCardWideNew,
  avatarTransformation,
  resizeCrop,
  resizeScale,
  nameTextOverlayOnlyForHostPage,
};
