import * as React from 'react';

import { SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import { Outlet } from 'react-router-dom';

// https://www.robinwieruch.de/react-router-private-routes/
// This can be used as a layout route, as well as wrapper

const ProtectedRoute = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <>
      <SignedIn>{children ? children : <Outlet />}</SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

export default ProtectedRoute;
