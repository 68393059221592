import React from 'react';

import * as Sentry from '@sentry/react';

export type SentryFallbackComponentProps = {
  error: Error;
  componentStack: any;
  resetError: () => any;
};

export type SentryErrorBoundaryProps = {
  children: JSX.Element;
  fallback:
    | JSX.Element
    | ((props?: SentryFallbackComponentProps) => JSX.Element);
};

export function SentryErrorBoundary({
  children,
  fallback,
}: SentryErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  );
}
