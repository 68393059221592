import { Outlet } from 'react-router-dom';
import ApolloProviderWithAuth from 'src/providers/ApolloProviderWithAuth';
import AuthProviderWithHistory from 'src/providers/AuthProviderWithHistory';
import G6AppStateProvider from 'src/providers/G6AppStateProvider';

const Layout = () => {
  return (
    <AuthProviderWithHistory>
      <ApolloProviderWithAuth>
        <G6AppStateProvider>
          <Outlet />
        </G6AppStateProvider>
      </ApolloProviderWithAuth>
    </AuthProviderWithHistory>
  );
};

export default Layout;
