import * as React from 'react';

// eslint-disable-next-line import/order
import { ChakraProvider } from '@chakra-ui/react';

import { enableMapSet } from 'immer';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CloudImageProvider from 'src/providers/CloudImageProvider';

import { ChakraTheme } from './chakra-theme';
import { initErrorHandler, ErrorBoundary } from './components/ErrorHandler';
import LoadingOverlay from './components/Loading/LoadingOverlay';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import reportWebVitals from './reportWebVitals';
import analytics from './utils/helpers/analyticsHelpers';
import FourOhFour from './views/FourOhFour/FourOhFour';
import Layout from './views/Layout/Layout';
import SignInScreen from './views/LoginFlowScreens/SignInScreen';
import SignUpScreen from './views/LoginFlowScreens/SignUpScreen';
import TestPage from './views/TestPage/TestPage';

import './index.css';

enableMapSet(); // for immer to work with Maps
initErrorHandler();
analytics.init();

// ** Lazy imports

const HomePage = React.lazy(() => import('./views/HomePage/HomePage'));
const HostPage = React.lazy(() => import('./views/HostPage/HostPage'));
const SeatSelectionPage = React.lazy(
  () => import('./views/SeatSelectionPage/SeatSelectionPage')
);
const SuccessPage = React.lazy(() => import('./views/SuccessPage/SuccessPage'));
const EventPage = React.lazy(() => import('./views/EventPage/EventPage'));
const TestScreen = React.lazy(() => import('./views/TestScreen/TestScreen'));
const RedeemPage = React.lazy(() => import('./views/RedeemPage/RedeemPage'));
const HostLandingPage = React.lazy(
  () => import('./views/HostLandingPage/HostLandingPage')
);
const SettingsScreen = React.lazy(
  () => import('./views/SettingsScreen/SettingsScreen')
);
const UserBaseProfile = React.lazy(
  () => import('./views/UserBaseProfile/UserBaseProfile')
);

const OnboardingRoot = React.lazy(
  () => import('./views/OnboardingScreen/OnboardingRoot')
);

const HostDashboard = React.lazy(
  () => import('./views/HostDashboard/HostDashboard')
);
const HostsListScreen = React.lazy(
  () => import('./views/HostsListScreen/HostsListScreen')
);

const VideoAppWrapper = React.lazy(
  () => import('src/components/VideoAppWrapper/VideoAppWrapper')
);
const StripeConnectCBScreen = React.lazy(
  () => import('./views/StripeConnectCBScreen/StripeConnectCBScreen')
);

const RedirectToHomeOnError = () => {
  return (
    <div className="error-boundary">
      Oops! Something went wrong. Don't worry we have been notified, meanwhile
      you can go back to{' '}
      <a className="home-link" href="/">
        Home Page
      </a>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={RedirectToHomeOnError}>
      <ChakraProvider theme={ChakraTheme}>
        <CloudImageProvider>
          <BrowserRouter>
            <React.Suspense fallback={<LoadingOverlay isOverlayOpen />}>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<HomePage />} />

                  {/* Individual Hosts Pages */}
                  <Route path="hosts">
                    <Route path=":hostSlug/:eventSlug" element={<HostPage />} />
                    <Route path=":hostSlug" element={<HostPage />} />
                  </Route>

                  {/* Seat Selection */}
                  <Route
                    path="seats/:eventSlug"
                    element={<SeatSelectionPage />}
                  />

                  {/* Host List Page */}
                  <Route
                    path="hosts-list/:interest"
                    element={<HostsListScreen />}
                  />
                  <Route path="hosts-list" element={<HostsListScreen />} />

                  {/* PROTECTED SET */}
                  <Route element={<ProtectedRoute />}>
                    {/* Success Page */}
                    <Route
                      path="success/:eventSlug"
                      element={<SuccessPage />}
                    />

                    {/* Event Page */}
                    <Route path="event/:eventSlug" element={<EventPage />} />

                    {/* Profile */}
                    <Route path="profile" element={<UserBaseProfile />} />

                    {/* Host Dashboard */}
                    <Route path="dashboard" element={<HostDashboard />} />

                    {/* User Settings */}
                    <Route path="settings" element={<SettingsScreen />} />

                    {/* Host Onboarding */}
                    <Route path="onboarding" element={<OnboardingRoot />} />

                    {/* Video */}
                    <Route
                      path="video/room/:eventID"
                      element={<VideoAppWrapper />}
                    />
                  </Route>

                  {/* Redeem page */}
                  <Route path="redeem">
                    <Route path=":giftCode" element={<RedeemPage />} />
                  </Route>

                  {/* Host Landing page */}
                  <Route path="host-landing" element={<HostLandingPage />} />

                  {/* Stripe Connect */}
                  <Route
                    path="stripe-cnct-cb/:context"
                    element={<StripeConnectCBScreen />}
                  />
                  <Route
                    path="stripe-cnct-cb"
                    element={<StripeConnectCBScreen />}
                  />

                  {/* Signup - Signin */}
                  <Route path="sign-in/*" element={<SignInScreen />} />
                  <Route path="sign-up/*" element={<SignUpScreen />} />

                  {/* Test stuff */}
                  <Route path="homey" element={<TestScreen />} />
                  <Route path="test" element={<TestPage />} />

                  <Route path="*" element={<FourOhFour />} />
                </Route>
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </CloudImageProvider>
      </ChakraProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
