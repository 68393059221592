import * as React from 'react';

import { Center, Text, HStack, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const FourOhFour = () => {
  const navigate = useNavigate();

  return (
    <Center h="100vh" bg="black">
      <Flex flexDirection="column" alignItems="center">
        <Text variant="heading" color="#fff">
          404. No such page exists
        </Text>

        <HStack spacing="24px" mt="96px">
          <Button variant="outline" w="96px" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button variant="outline" w="96px" onClick={() => navigate('/')}>
            Home
          </Button>
        </HStack>
      </Flex>
    </Center>
  );
};

export default FourOhFour;
