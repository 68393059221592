import * as React from 'react';

import { Box } from '@chakra-ui/react';
import { SignIn } from '@clerk/clerk-react';

const SignInScreen = () => {
  return (
    <Box bgColor={'#000'} h={'100vh'} pt="20px">
      <SignIn routing="path" path="/sign-in" />
    </Box>
  );
};

export default SignInScreen;
